import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import HiringSection from '../../components/Hiring'
import ContactForm from '../../components/ContactForm'
import FeatureWithImage from '../../components/FeatureWithImage'
import Layout from '../../components/Layout'

import dotNetImage1 from '../../img/pages/technologies/dotnet/3rd-party.png'
import dotNetImage2 from '../../img/pages/technologies/dotnet/integration.png'
import dotNetImage3 from '../../img/pages/technologies/dotnet/asp-net.png'
import dotNetImage4 from '../../img/pages/technologies/dotnet/migration.png'

const TechnologyStackTitle = styled.span`
  display: block; font-size: 20px; margin-bottom: 15px;
`
const TechnologyStackUL = styled.ul`
  overflow: hidden; margin: 0; padding: 0;
`
const TechnologyStackLI = styled.li`
  position: relative; list-style: none; padding: 8px 15px; box-sizing: border-box;
  &:before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: gray;
    background-color: ${props => props.color};
    position: absolute;
    top: 18px;
    left: 0;
  }
`


export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="technologies">
        <Helmet
          title=".NET Software Development | Bishop Software"
          meta={[
            { name: 'description', content: 'Our in-house team of .NET developers has the technical expertise to design and develop high-end .NET and .NET Core applications. Let\'s talk.' },
            { name: 'keywords', content: '.net,dotnet,core,entity framework,c#,software,development,web,design,mobile,consultancy,consulting,website,application' }
          ]}
        />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #09203A 0%, #207cca 73%, #6977B2 90%, #BC6591 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#1076B8 0px, #072944 100%)' section="technologies" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
              <h1 style={{color: 'rgb(243, 174, 189)', fontSize: '40px', fontWeight: 300}}>.NET Development Company</h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>Full-cycle dot net development services to produce 
high-performance, interactive, and powerful enterprise solutions using .NET technology.</h3>
              <div style={{marginTop: '35px', paddingBottom: '55px'}}>
                <Link to="/contact" className="round btn btn-primary">Hire our .NET Developers</Link>
              </div>
            </div>
          </Container>
        </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fff 30%, #fefefe 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col>
            <p>.NET technology assists in creating applications of any complexity. The key advantage of the technology is the versatility that allows for developing solutions for companies on any device, at any time.</p>
            <p>Bishop Software’s in-house team of ASP.NET developers has the professional technical expertise to design and develop high-end .NET web applications for different business industries and sectors. Our ASP.NET developers are available for hire on a full time or an hourly basis to create the most flexible and scalable .NET web-based solutions.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fff 30%, #fefefe 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col>
            <h1 className="text-center" style={{marginBottom: '45px'}}>.NET Services</h1>
            <FeatureWithImage title=".NET Integration Services" imageSrc={dotNetImage2}>Our team has the qualification and ability to help businesses integrate and manage their .NET solution in virtually any development environment.</FeatureWithImage>
            <FeatureWithImage title="Custom ASP.NET Development" imageSrc={dotNetImage3} right={true}>We can undertake a project of any complexity and size and create a complete solution adapted to the needs of the customer. Our ASP.NET web development company focuses primarily on custom .NET development. Bishop Software .NET  developers create multi-tier robust .NET web solutions for small and large enterprises in both ASP.NET and ASP.NET Core.</FeatureWithImage>
            <FeatureWithImage title="Migration to .NET / .NET Core" imageSrc={dotNetImage1}>If you use obsolete technologies and would like to migrate legacy desktop apps to the web and to .NET in particular, we can make this migration smooth and boost your application performance.</FeatureWithImage>
            <FeatureWithImage title="Migration to the Cloud" imageSrc={dotNetImage4} right={true}>Our .NET experts can handle the most complex . NET applications and easily deploy and scale them to the cloud. We can run your app on Windows Azure, which is the best PaaS for .NET, however, we can run your solution on any .NET PaaS or can deploy it in a hybrid cloud environment in accordance with your app scale.</FeatureWithImage>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{backgroundImage: 'linear-gradient(179deg, #2c2f39 2%, #363944 14%, #32353d 100%)', color: '#ccc', padding: '70px 0'}}>
      <Container>
        <h2 style={{color: '#fff', textAlign: 'center', marginBottom: '45px'}}>Technology Stack</h2>
        <Row>
          <Col xs="6" md="3">
            <TechnologyStackTitle style={{color: '#f35038'}}>UI</TechnologyStackTitle>
            <TechnologyStackUL>
              <TechnologyStackLI color="#f35038">DevExpress</TechnologyStackLI>
              <TechnologyStackLI color="#f35038">DHTMLX</TechnologyStackLI>
              <TechnologyStackLI color="#f35038">React.js</TechnologyStackLI>
              <TechnologyStackLI color="#f35038">Angular</TechnologyStackLI>
              <TechnologyStackLI color="#f35038">Vue</TechnologyStackLI>
              <TechnologyStackLI color="#f35038">Xamarin</TechnologyStackLI>
            </TechnologyStackUL>

            <TechnologyStackTitle style={{color: '#ff9000', marginTop: '25px'}}>CMS</TechnologyStackTitle>
            <TechnologyStackUL>
              <TechnologyStackLI color="#ff9000">Umbraco</TechnologyStackLI>
              <TechnologyStackLI color="#ff9000">Orchard</TechnologyStackLI>
              <TechnologyStackLI color="#ff9000">DotNetNuke</TechnologyStackLI>
              <TechnologyStackLI color="#ff9000">mojoPortal</TechnologyStackLI>
              <TechnologyStackLI color="#ff9000">SiteFinity</TechnologyStackLI>
              <TechnologyStackLI color="#ff9000">Kentico</TechnologyStackLI>
            </TechnologyStackUL>
          </Col>
          <Col xs="6" md="3">
            <TechnologyStackTitle style={{color: '#3bc448'}}>Products</TechnologyStackTitle>
            <TechnologyStackUL>
              <TechnologyStackLI color="#3bc448">Office 365</TechnologyStackLI>
              <TechnologyStackLI color="#3bc448">BizTalk</TechnologyStackLI>
              <TechnologyStackLI color="#3bc448">Microsoft Azure</TechnologyStackLI>
              <TechnologyStackLI color="#3bc448">Amazon web services EC2</TechnologyStackLI>
              <TechnologyStackLI color="#3bc448">Exchange Server</TechnologyStackLI>
              <TechnologyStackLI color="#3bc448">MS Dynamics</TechnologyStackLI>
              <TechnologyStackLI color="#3bc448">SharePoint</TechnologyStackLI>
            </TechnologyStackUL>

            <TechnologyStackTitle style={{color: '#c23481', marginTop: '25px'}}>DBMS</TechnologyStackTitle>
            <TechnologyStackUL>
              <TechnologyStackLI color="#c23481">Oracle</TechnologyStackLI>
              <TechnologyStackLI color="#c23481">SQL Server Compact</TechnologyStackLI>
              <TechnologyStackLI color="#c23481">Sybase</TechnologyStackLI>
              <TechnologyStackLI color="#c23481">MySQL</TechnologyStackLI>
              <TechnologyStackLI color="#c23481">SQL Server</TechnologyStackLI>
              <TechnologyStackLI color="#c23481">PostgreSQL</TechnologyStackLI>
            </TechnologyStackUL>
          </Col>
          <Col xs="6" md="3">
            <TechnologyStackTitle style={{color: '#0ad4db'}}>Security</TechnologyStackTitle>
            <TechnologyStackUL>
              <TechnologyStackLI color="#0ad4db">HTTPS/SSL</TechnologyStackLI>
              <TechnologyStackLI color="#0ad4db">NTLM</TechnologyStackLI>
              <TechnologyStackLI color="#0ad4db">X.509</TechnologyStackLI>
              <TechnologyStackLI color="#0ad4db">SSO</TechnologyStackLI>
              <TechnologyStackLI color="#0ad4db">Encryption</TechnologyStackLI>
              <TechnologyStackLI color="#0ad4db">Kerberos</TechnologyStackLI>
              <TechnologyStackLI color="#0ad4db">Auditing</TechnologyStackLI>
              <TechnologyStackLI color="#0ad4db">Logging</TechnologyStackLI>
              <TechnologyStackLI color="#0ad4db">OAuth 2.0</TechnologyStackLI>
            </TechnologyStackUL>

            <TechnologyStackTitle style={{color: '#0e73bc', marginTop: '25px'}}>.NET Frameworks</TechnologyStackTitle>
            <TechnologyStackUL>
              <TechnologyStackLI color="#0e73bc">ASP.NET</TechnologyStackLI>
              <TechnologyStackLI color="#0e73bc">ASP.NET Core</TechnologyStackLI>
              <TechnologyStackLI color="#0e73bc">ASP.NET MVC</TechnologyStackLI>
              <TechnologyStackLI color="#0e73bc">ASP.NET Web API</TechnologyStackLI>
              <TechnologyStackLI color="#0e73bc">ASP.NET SignalR</TechnologyStackLI>
            </TechnologyStackUL>
          </Col>
          <Col xs="6" md="3">
            <TechnologyStackTitle style={{color: '#0bd894'}}>Web Services</TechnologyStackTitle>
            <TechnologyStackUL>
              <TechnologyStackLI color="#0bd894">OData</TechnologyStackLI>
              <TechnologyStackLI color="#0bd894">SOAP</TechnologyStackLI>
              <TechnologyStackLI color="#0bd894">REST</TechnologyStackLI>
              <TechnologyStackLI color="#0bd894">WCF Data Services</TechnologyStackLI>
              <TechnologyStackLI color="#0bd894">WCF Services</TechnologyStackLI>
            </TechnologyStackUL>

            <TechnologyStackTitle style={{color: '#a151ea', marginTop: '25px'}}>Cloud Providers</TechnologyStackTitle>
            <TechnologyStackUL>
              <TechnologyStackLI color="#a151ea">Microsoft Azure</TechnologyStackLI>
              <TechnologyStackLI color="#a151ea">Amazon AWS</TechnologyStackLI>
              <TechnologyStackLI color="#a151ea">Google Cloud Platform</TechnologyStackLI>
            </TechnologyStackUL>

            <TechnologyStackTitle style={{color: '#e62851', marginTop: '25px'}}>RTC Technologies</TechnologyStackTitle>
            <TechnologyStackUL>
              <TechnologyStackLI color="#e62851">WebRTC</TechnologyStackLI>
              <TechnologyStackLI color="#e62851">WebSockets</TechnologyStackLI>
            </TechnologyStackUL>
          </Col>
        </Row>
        
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

        <HiringSection />

        <Footer section="technologies" />
      </div>
  </Layout>
    )
  }
}
  